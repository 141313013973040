
.checkout {
  // TODO: fix this to framework color
  background-color: #faf9f6; // var(--bg-4)
  padding-bottom: var(--margin-l);
}
.offline-checkout-explain {
  width: 100%;
  flex: 100%;
  margin-inline: auto;

  @media (max-width: 1024px) {
    max-width: 700px;
    height: auto;
  }
}
.order-area {
  width: 100%;
  flex: 100%;
  margin-inline: auto;
  max-width: var(--container-l);
  padding: 0 var(--container-gutter) 0 var(--container-gutter);
  margin-bottom: var(--margin-xl);

  @media (max-width: 1024px) {
    max-width: 700px;
    height: auto;
    margin: 0;
    padding-top: 0;
  }
}

.order-forms {
  padding-inline: var(--margin-l);

  padding-top: var(--margin-max);

  @media (max-width: 1024px) {
    padding-inline: 0;
  }

  .checkout-step {
    margin-bottom: var(--margin-xl);
    h4 {
      margin-bottom: var(--margin-m);
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.cart-container {
  max-width: 400px;
  position: relative;
  padding-top: var(--margin-max);
  padding-inline: var(--margin-l);
  padding-bottom: var(--margin-l);

  @media (max-width: 1024px) {
    height: auto;
    max-width: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0;
  }

  .cart-expander {
    background-color: var(--c-gray-5);
    border-bottom: 1px solid var(--c-gray-2);
    box-shadow: var(--box-shadow-2);
    color: var(--c-bg);
    padding-block: var(--margin-s);
    cursor: pointer;
  }

  .cart-container-inner {
    width: 360px;
    position: sticky;
    top: 120px; // TODO: this depends on  header height

    @media (max-width: 1024px) {
      position: relative;
      top: 0;
      max-width: 100%;
      min-width: 0;
      width: 100%;
      display: none;
      padding: var(--margin-m);
      background: var(--c-gray);
      z-index: var(--z-2);
      max-height: 60vh;
      overflow-y: scroll;

      &.expanded {
        display: block;
      }
    }
  }

  .cart {
    @media (max-width: 1024px) {
      background-color: var(--c-bg);
    }
  }
}

.checkout-step-subtitle {
  font-size: var(--text-l);
  font-weight: normal;
}
